<template>
  <head-top>
    <div class="order-list">
      <div class="tab-top">
        <van-tabs v-model="activeName" @change="changeItem">
          <van-tab
            v-for="(item, index) in activeList"
            :key="index"
            :title="item.name"
            :name="item.key"
          >
          </van-tab>
        </van-tabs>
      </div>

      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div :style="{ height: '100%' }">
          <van-list
            offset="300"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onLoad"
          >
            <div class="list-items">
              <div
                class="item"
                v-for="(item, index) in list"
                :key="index"
                @click="clickDetails(item.orderId)"
              >
                <div class="title">
                  <span class="title-left">{{ item.hotelName }}</span>
                  <div
                    class="title-right"
                    v-if="
                      item.status == 1 || item.status == 2 || item.status == 3
                    "
                  >
                    <span class="title-fukuang">等待付款</span>
                    <span class="title-date">
                      {{ item.fen ? item.fen : "00" }}分{{
                        item.miao ? item.miao : "00"
                      }}秒
                    </span>
                  </div>
                </div>
                <div class="item-date">
                  <span class="left">预定日期</span>
                  <span class="middle"
                    >{{ item.checkTime }} 至 {{ item.leaveTime }}</span
                  >
                  <span class="right">共{{ item.days }}晚</span>
                </div>
                <div class="package" v-for="(v, i) in item.orderRooms" :key="i">
                  <span class="package-type">{{ v.type }}</span>
                  <span class="package-number">x{{ v.num }}</span>
                </div>
                <div
                  class="pay-date"
                  v-if="
                    item.status == 1 || item.status == 2 || item.status == 3
                  "
                >
                  10分钟未支付将会自动取消订单
                </div>
                <div class="pay-state">
                  <i
                    :class="{
                      'item-icom-two':
                        item.status == 1 ||
                        item.status == 2 ||
                        item.status == 3,
                      'item-icom-one': item.status == 4,
                      'item-icom-three': item.status == 5,
                    }"
                  ></i>
                  <span class="state">{{
                    item.status == 1 || item.status == 2
                      ? "待支付"
                      : item.status == 3
                      ? "支付失败"
                      : item.status == 4
                      ? "支付成功"
                      : "已取消"
                  }}</span>
                  <span
                    @click.stop="payClick(item)"
                    class="button"
                    v-if="
                      item.status == 1 || item.status == 2 || item.status == 3
                    "
                    >支付</span
                  >
                  <span class="pay-amount">¥{{ zhuanfen(item.sumPrice) }}</span>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-pull-refresh>
    </div>
  </head-top>
</template>

<script>
import headTop from "../../components/head.vue";
import { orderList } from "../../api/home/index.js";
export default {
  data() {
    return {
      fen: "00",
      miao: "00",
      activeName: "",
      activeList: [
        {
          name: "全部订单",
          key: "",
        },
        {
          name: "待支付",
          key: "1001",
        },
        {
          name: "已完成",
          key: "1002",
        },
        {
          name: "已取消",
          key: "1003",
        },
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      pageSize: 10,
      _interval: "",
    };
  },
  components: {
    headTop,
  },
  created() {
    this.orderList();
  },
  //当离开页面时，清除倒计时
  beforeDestroy() {
    clearInterval(this._interval);
  },
  methods: {
    //倒计时事件
    countdown(countTime, index) {
      const that = this;
      that._interval = setInterval(() => {
        if (countTime == 0) {
          // 计时结束，清除缓存
          clearInterval(that._interval);
        } else {
          countTime--;
          let min = parseInt((countTime / 60) % 60);
          let sec = parseInt(countTime % 60);
          min = min > 9 ? min : "0" + min;
          sec = sec > 9 ? sec : "0" + sec;
          this.$set(that.list[index], "fen", min);
          this.$set(that.list[index], "miao", sec);
          this.$set(that.list[index], "remainSec", countTime);
        }
      }, 1000);
    },
    zhuanfen(roomPrice) {
      return (roomPrice / 100).toFixed(2);
    },
    // 支付
    payClick(item) {
      this.$router.push({
        name: "pay",
        query: {
          orderId: item.orderId,
          amountTotal: item.sumPrice,
          remainSec: item.remainSec,
        },
      });
    },
    async orderList(isRefresh) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        catagoryStatus: this.activeName,
      };
      let res = await orderList(data);
      this.loading = false;
      this.refreshing = false;
      if (res.code == 200) {
        let list = res.rows;
        if (isRefresh) {
          this.list = this.list.concat(list);
        } else {
          this.list = list;
        }
        this.list.forEach((item, index) => {
          if (item.status == 1 || item.status == 2 || item.status == 3) {
            this.countdown(item.remainSec, index);
          }
        });
        if (this.list.length < res.total) {
          this.loading = false;
        } else {
          this.finished = true;
          this.loading = true;
        }
        if (this.refreshing) {
          this.refreshing = false;
        }
        console.log(this.list, "this.listthis.listthis.list");
      }
    },
    // 订单详情
    clickDetails(orderId) {
      this.$router.push({
        name: "payDetails",
        query: {
          orderId: orderId,
        },
      });
    },
    // 切换tab
    changeItem() {
      this.list = [];
      this.pageNum = 1;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.orderList();
    },
    // 上拉加载
    onLoad() {
      this.pageNum += 1;
      this.orderList(true);
    },
    // 下拉刷新
    onRefresh() {
      // 清空列表数据
      this.pageNum = 1;
      this.finished = false;
      this.loading = true;
      this.refreshing = true;
      this.list = [];
      this.orderList(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.order-list {
  height: 100vh;
  padding-top: 80px;
  .list-items {
    // height: calc(100vh - -50px);
    // height: 100%;
    background: #f1f1f1;
    padding: 20px;
    .item {
      // height: 260px;
      margin-bottom: 30px;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.21);
      border-radius: 5px;
      padding: 20px;
      .title {
        color: rgba(80, 80, 80, 1);
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px;
        .title-right {
          display: inline-block;
          float: right;
          background: rgba(247, 173, 164, 1);
          color: rgba(228, 105, 109, 1);
          border-radius: 20px 0px 20px 20px;
          .title-fukuang {
            font-size: 30px;
            display: inline-block;
            padding: 5px 10px;
            color: #fff;
            background: red;
            border-radius: 20px;
          }
          .title-date {
            padding: 0 5px;
          }
        }
      }
      .item-date {
        color: rgba(80, 80, 80, 1);
        font-size: 28px;
        padding: 0 115px;
        position: relative;
        display: flex;
        text-align: center;
        margin-bottom: 30px;
        .left {
          width: 115px;
          position: absolute;
          left: 0px;
        }
        .middle {
          flex: 1;
        }
        .right {
          width: 115px;
          position: absolute;
          right: 0px;
          text-align: right;
        }
      }
      .package {
        text-align: right;
        color: rgba(80, 80, 80, 1);
        font-size: 28px;
        font-weight: 600;
        padding: 0 5px;
        .package-type {
          margin-right: 30px;
        }
      }
      .pay-date {
        font-size: 20px;
        color: red;
        margin-top: 20px;
      }
      .pay-state {
        margin-top: 20px;
        display: flex;
        justify-content: left;
        align-items: center;
        position: relative;
        .item-icom-one {
          display: inline-block;
          width: 30px;
          height: 30px;
          background-image: url("../../assets/already-pay.png");
          background-size: cover;
          vertical-align: middle;
          margin-right: 8px;
        }
        .item-icom-two {
          display: inline-block;
          width: 30px;
          height: 30px;
          background-image: url("../../assets/stay-pay.png");
          background-size: cover;
          vertical-align: middle;
          margin-right: 8px;
        }
        .item-icom-three {
          display: inline-block;
          width: 30px;
          height: 30px;
          background-image: url("../../assets/cancel-pay.png");
          background-size: cover;
          vertical-align: middle;
          margin-right: 8px;
        }
        .state {
          font-size: 28px;
          margin-right: 20px;
        }
        .button {
          height: 34px;
          color: rgba(0, 0, 0, 1);
          background-color: rgba(255, 255, 255, 1);
          border-radius: 5px;
          border: 2px solid #000;
          padding: 10px 20px;
          font-size: 28px;
        }
        .pay-amount {
          float: right;
          font-size: 36px;
          color: red;
          font-weight: 600;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.order-list {
  .tab-top {
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 999;
    border-top: 1px solid #ccc;
    .van-tab--active {
      color: red;
    }
    .van-tabs__line {
      background-color: red;
    }
  }
  .van-list {
    background: #f1f1f1;
  }
}
</style>
