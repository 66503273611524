var render = function render(){var _vm=this,_c=_vm._self._c;return _c('head-top',[_c('div',{staticClass:"order-list"},[_c('div',{staticClass:"tab-top"},[_c('van-tabs',{on:{"change":_vm.changeItem},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.activeList),function(item,index){return _c('van-tab',{key:index,attrs:{"title":item.name,"name":item.key}})}),1)],1),_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('div',{style:({ height: '100%' })},[_c('van-list',{attrs:{"offset":"300","finished":_vm.finished,"finished-text":"没有更多了","immediate-check":false},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"list-items"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.clickDetails(item.orderId)}}},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-left"},[_vm._v(_vm._s(item.hotelName))]),(
                    item.status == 1 || item.status == 2 || item.status == 3
                  )?_c('div',{staticClass:"title-right"},[_c('span',{staticClass:"title-fukuang"},[_vm._v("等待付款")]),_c('span',{staticClass:"title-date"},[_vm._v(" "+_vm._s(item.fen ? item.fen : "00")+"分"+_vm._s(item.miao ? item.miao : "00")+"秒 ")])]):_vm._e()]),_c('div',{staticClass:"item-date"},[_c('span',{staticClass:"left"},[_vm._v("预定日期")]),_c('span',{staticClass:"middle"},[_vm._v(_vm._s(item.checkTime)+" 至 "+_vm._s(item.leaveTime))]),_c('span',{staticClass:"right"},[_vm._v("共"+_vm._s(item.days)+"晚")])]),_vm._l((item.orderRooms),function(v,i){return _c('div',{key:i,staticClass:"package"},[_c('span',{staticClass:"package-type"},[_vm._v(_vm._s(v.type))]),_c('span',{staticClass:"package-number"},[_vm._v("x"+_vm._s(v.num))])])}),(
                  item.status == 1 || item.status == 2 || item.status == 3
                )?_c('div',{staticClass:"pay-date"},[_vm._v(" 10分钟未支付将会自动取消订单 ")]):_vm._e(),_c('div',{staticClass:"pay-state"},[_c('i',{class:{
                    'item-icom-two':
                      item.status == 1 ||
                      item.status == 2 ||
                      item.status == 3,
                    'item-icom-one': item.status == 4,
                    'item-icom-three': item.status == 5,
                  }}),_c('span',{staticClass:"state"},[_vm._v(_vm._s(item.status == 1 || item.status == 2 ? "待支付" : item.status == 3 ? "支付失败" : item.status == 4 ? "支付成功" : "已取消"))]),(
                    item.status == 1 || item.status == 2 || item.status == 3
                  )?_c('span',{staticClass:"button",on:{"click":function($event){$event.stopPropagation();return _vm.payClick(item)}}},[_vm._v("支付")]):_vm._e(),_c('span',{staticClass:"pay-amount"},[_vm._v("¥"+_vm._s(_vm.zhuanfen(item.sumPrice)))])])],2)}),0)])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }